import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Divider,
  CircularProgress,
  Typography,
  Link, InputAdornment, IconButton
} from "@material-ui/core";
import axios from "utils/axios";
import apiConfig from "apiConfig";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { INIT_COMPANY } from "../../redux/actions/action-types";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { PERMISSIONS } from 'common/permissions'
import {Visibility, VisibilityOff} from "@material-ui/icons";

const schema = {
  username: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 3
    }
  },
  password: {
    presence: { allowEmpty: false, message: "is required" }
  }
};


const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1, 2)
  },
  grid: {
    height: "100%"
  },

  form: {
    width: '500px',
    maxWidth: '100%',
    paddingLeft: 50,
    paddingRight: 50,
    paddingBottom: 50,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },

  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  cardHeaderTitle: {
    textAlign: "center"
  }
}));

const SignIn = props => {
  const { history } = props;
  const classes = useStyles();

  const [isStatus, setIsStatus] = useState({ failed: false, msg: "" });
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      username: "",
      password: ""
    },
    touched: {},
    errors: {}
  });
  const [isProgress, setIsProgress] = useState(false);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignIn = event => {
    event.preventDefault();
    let postData = {
      username: formState.values.username,
      password: formState.values.password
    };
    setIsProgress(true);
    axios
      .post(apiConfig.url.AUTH_URL + apiConfig.url.SIGN_IN, postData)
      .then(response => {
        const token = {
          token: response.data.token,
          expiresOn: response.data.expiresOn
        };
        const usr = {
          userName: response.data.userName,
          displayName: response.data.displayName,
          avatar: response.data.avatar
        };
        const info = jwt_decode(token.token);
        const alerts = response.data.globalAlerts;
        localStorage.setItem("companyId", info.cid);
        localStorage.setItem("sw-token", JSON.stringify(token));
        localStorage.setItem("sw-usr", JSON.stringify(usr));
        localStorage.setItem("sw-role", JSON.stringify(info.role));
        localStorage.setItem("sw-alerts", JSON.stringify(alerts));

        axios.defaults.headers["Authorization"] = `Bearer ${token.token}`;
        setIsProgress(false);
        const defaultPage = info.role.includes(PERMISSIONS.FIELD_TECHNICIAN) ? '/employees/today-route' : '/';
        history.push(defaultPage);
        // Stop cold start
        axios.get(apiConfig.url.CUSTOMER_URL + "ping");
        axios.get(apiConfig.url.USER_URL + "ping");
      })
      .catch(ex => {
        setIsStatus({
          failed: true,
          msg:
            ex.response.status === 400
              ? "The Username or Password does not match.  Please try again"
              : ex.message
        });
        setOpenSnackBar(true);
        setIsProgress(false);
      });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Card>
        <CardHeader
          className={classes.cardHeaderTitle}
          classes={{
            title: classes.cardHeaderTitle
          }}
          title="Welcome to SWO"
        />
        <Divider />
        <CardContent>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Email"
                  name="username"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.username || ""}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={formState.values.password || ""}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                              onClick={() => {
                                setShowPassword(prev => !prev);
                              }}
                              edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                    )
                  }}
                />
                <div style={{ textAlign: "center" }}>
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid || isProgress}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                  <Link href='sign-in/forgot-password'> Forgot Your Password? </Link>
                  {isProgress && <CircularProgress size={24} />}
                </div>
              </form>
            </div>
          </div>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={isStatus.failed ? "error" : "success"}
        >
          <Typography color="inherit" variant="h6">
            {isStatus.msg}
          </Typography>
        </Alert>
      </Snackbar>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
